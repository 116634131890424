import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CaseDetail from '../components/Cases/CaseDetail'
import Hero from '../components/Hero'

export default class BusinessDevelopmentCaseStudy extends Component {
  render() {
    const caseTopImage = require('../assets/images/rawpixel-252130-unsplash.jpg')
    const caseProblemImage = require('../assets/images/rawpixel-252130-unsplash.jpg')

    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Business Development"
            description="Market entry for healthcare"
          />
          <CaseDetail
            caseClient="Medpages"
            caseTitle="Medpages"
            caseDate="17 August 2017"
            caseTopImage={caseTopImage}
            caseProblemImage={caseProblemImage}
            caseHighlight={[
              '4X increase in new non-local customer through a structured customer acquisition plan',
              'Interactive customer discovery, on-boarding and Sub Saharan key account management activity',
              'Improved network of key industry players that enhanced value-adding, continuous interaction within African value chain',
              'Management and tracking of new opportunities through a structured SSA partnership model',
            ]}
            caseDescription="Our client, a leading healthcare data management company in South Africa was
            seeking client diversity and growth in Sub Sharan Africa through new client market
            entry, discovery and customer acquisition "
            caseProblemDescription="Healthcare contact data analytics was a
            growing service offering with no adoption from Sub Saharan customers. Among the
            challenges were; inadequate Information sourcing, unestablished key partner
            network and unrealized data-centric decision making system in the region."
            caseProblemDescriptionHighlight="Bora Growth Partners was engaged as Business Development partner to address
            the above challenges and structure an interactive, continuous and progressive
            mechanism to discover new market opportunities in the Sub Sahara Africa region."
            caseFeedback="We were able to rely on Bora Growth team in doing the work we did not have in-house support for. They managed to take our senior team to East Africa and this showed immediate results. KUDOS guys"
          />
        </div>
      </Layout>
    )
  }
}
